import { useSessionUser } from '@/hooks/useSessionUser';
import { updateUserNameSchema } from '@/schema/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Edit } from '@styled-icons/material-outlined/Edit';

import EdgeButton from '../_buttons/EdgeButton';
import { Avatar, AvatarButton } from '../_catalyst/avatar';
import UserAvatarUpdateModal from '../_modals/UserAvatarUpdateModal';
import { SteamUser } from '@/types/user';
import Image from 'next/image';
import Select from '../Select';
import { useRouter } from 'next/router';
import { useRoomStore } from '@/hooks/stores/useRoomStore';

const options = [
  {
    label: '日本語',
    value: 'jp',
  },
  {
    label: 'English',
    value: 'us',
  },
  {
    label: '한국어',
    value: 'kr',
  },
];

interface Props {
  onChangeName?: (userId: string, name: string) => void;
  onChangeAvatar?: (user: SteamUser) => void;
}

type FormData = { name: string };

export default function UserSettings({ onChangeName, onChangeAvatar }: Props) {
  const { user, login, updateUserName } = useSessionUser();
  const router = useRouter();
  const { roomId: storedRoomId, setRoomId } = useRoomStore();
  const { t, i18n } = useTranslation();
  const [isDisplayAvatarModal, setIsDisplayAvatarModal] = React.useState(false);
  const [isHoverAvatar, setIsHoverAvatar] = React.useState(false);
  const [language, setLanguage] = React.useState(i18n.language ?? 'jp');

  const handleChangeServer = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
    await login(event.target.value);
    localStorage.setItem('region', event.target.value);
    setRoomId(undefined);
  };

  const handleOpenAvatarModal = () => {
    setIsDisplayAvatarModal(true);
  };

  const handleCloseAvatarModal = () => {
    setIsDisplayAvatarModal(false);
  };

  const handleHoverAvatar = () => {
    setIsHoverAvatar(true);
  };

  const handleLeaveAvatar = () => {
    setIsHoverAvatar(false);
  };

  React.useEffect(() => {
    // getCountryCode();
  }, []);

  const {
    formState: { errors },
    register,
    trigger,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: user ? user.name : '',
    },
    values: {
      name: user ? user.name : '',
    },
    resolver: yupResolver(updateUserNameSchema),
  });

  const { onBlur, ...fieldProps } = register('name', {
    required: { value: true, message: 'ニックネームは必須です' },
  });

  const handleUpdateName = async ({ name }: FormData) => {
    if (!user) return;
    await updateUserName(name);
    if (onChangeName) {
      onChangeName(user.id, name);
    }
  };

  return (
    <>
      {user && (
        <>
          <form onSubmit={handleSubmit(handleUpdateName)}>
            <div className="p-2 pt-0 mb-4">
              <label className="text-left font-bold text-brown-primary block text-lg ">
                {t('options.game.nickname')}
              </label>
              <div className="flex items-baseline gap-2">
                <div>
                  <div className="flex flex-col bg-[url('/images/input_bg_l.png')] w-[400px] bg-contain bg-left bg-no-repeat">
                    <input
                      className="border h-[60px] overflow-y-hidden input-custom text-white rounded-lg block w-full px-4"
                      placeholder="ニックネーム入力"
                      style={{
                        minWidth: '280px',
                        border: 'none',
                        background: 'transparent',
                      }}
                      onBlur={() => {
                        trigger('name');
                      }}
                      {...fieldProps}
                    />
                  </div>
                  {errors.name && (
                    <div className="text-sm text-red-600">
                      {errors.name.message}
                    </div>
                  )}
                </div>
                <EdgeButton
                  type="submit"
                  className="!w-[100px] !h-[50px] text-lg"
                  style={{ backgroundSize: '100% 100%' }}
                >
                  {t('save')}
                </EdgeButton>
              </div>
            </div>
          </form>
          {router.pathname === '/' && (
            <div className="p-2 pt-0 pb-4 mb-4 max-w-[200px]">
              <label className="text-left font-bold text-brown-primary block text-lg mb-2">
                {t('options.game.language')}
              </label>
              <Select
                className="text-base"
                value={language}
                onChange={handleChangeServer}
              >
                {options.map((option) => (
                  <option
                    key={option.value}
                    className="text-black"
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div className="p-2 pt-0 mb-4 h-[200px]">
            <div className="text-lg font-bold">{t('options.game.avatar')}</div>
            <div className="flex items-center h-full">
              {user.avatar ? (
                <div className="relative flex items-center gap-8">
                  <Image
                    className={`size-36 rounded-full cursor-pointer z-10 ${
                      isHoverAvatar ? 'opacity-50' : 'opacity-100'
                    }`}
                    width={144}
                    height={144}
                    alt="avatar"
                    src={`/avatars/${user.avatar.id}.jpg`}
                    onClick={handleOpenAvatarModal}
                    onMouseEnter={handleHoverAvatar}
                    onMouseLeave={handleLeaveAvatar}
                  />
                  {isHoverAvatar && (
                    <div className="absolute w-[144px] h-[144px] flex items-center justify-center">
                      <Edit size={48} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative flex items-center gap-8">
                  <Image
                    className={`size-36 rounded-full cursor-pointer z-10 ${
                      isHoverAvatar ? 'opacity-80' : 'opacity-100'
                    }`}
                    width={144}
                    height={144}
                    alt="avatar"
                    src={'/images/player_icon_default.png'}
                    onClick={handleOpenAvatarModal}
                    onMouseEnter={handleHoverAvatar}
                    onMouseLeave={handleLeaveAvatar}
                  />
                  {isHoverAvatar && (
                    <div className="absolute w-[144px] h-[144px] flex items-center justify-center">
                      <Edit size={48} />
                    </div>
                  )}
                </div>
              )}
            </div>
            {isDisplayAvatarModal && (
              <UserAvatarUpdateModal
                userAvatarId={user.avatarId}
                onClose={handleCloseAvatarModal}
                onSubmit={onChangeAvatar}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
