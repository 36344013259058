import { OriginalQuestionType } from '@/types/originalQuestion';
import yup from '@/yup.locale';
import { TFunction } from 'next-i18next';

export enum ReviewType {
  BAD,
  GOOD,
}

export enum AssistantToneType {
  NORMAL,
  GAL,
  KANSAI,
  LU,
  UNCLE,
  JAPANESE,
  LADY,
}

export const createUserSchema = yup.object().shape({
  name: yup.string().required().max(255),
});

export const createRoomSchema = yup.object().shape({
  title: yup.string().required().max(255),
  password: yup.string().optional().max(255),
});

export const updateRoomSchema = yup.object().shape({
  title: yup.string().required().max(255),

  penaltySeconds: yup.number().nullable().max(120),
  questionCooldownSeconds: yup.number().nullable().max(120),
  assistantToneType: yup
    .mixed()
    .oneOf(Object.values(AssistantToneType))
    .nullable()
    .default(AssistantToneType.NORMAL),
  isHintEnabled: yup.boolean().nullable().default(true),
});

export const createQuestionSchema = yup.object().shape({
  question: yup.string().required().max(2000),
  answer: yup.string().required().max(2000),
  answerForGpt: yup.string().optional().max(4000),
  title: yup.string().required().max(255),
  hint: yup.string().optional().max(2000),
  copyright: yup.string().optional().max(255),
  copyrightUrl: yup.string().optional().max(255),
  difficulty: yup.number().optional().min(1).max(5).default(3),
});

export const getQuestionsSchema = yup.object().shape({
  limit: yup.number().optional().max(50).default(20),
  offset: yup.number().optional().default(0),
  isIncludePlayed: yup.boolean().optional().default(false),
});

export const getRoomQuestionsSchema = yup.object().shape({
  limit: yup.number().optional().max(50).default(20),
  offset: yup.number().optional().default(0),
  roomId: yup.string().required(),
  isIncludePlayed: yup.boolean().optional().default(false),
});

export const joinRoomSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  password: yup.string().optional().max(255),
  userId: yup.string().required().max(21),
});

export const finishQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
});

export const askQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  message: yup.string().required().max(2000),
});

export const answerQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  message: yup
    .string()
    .required()
    .max(2000)
    .test(
      'no-correct-answer',
      '正解というテキストを含むことはできません',
      (value) => {
        return !/正解/.test(value);
      },
    ),
});

export const pickQuestionSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
});

export const pickGyakunatorSchema = yup.object().shape({
  question: yup.string().optional().max(2000),
  answer: yup.string().required().max(255),
  roomId: yup.string().required().max(21),
});

export const reviewQuestionSchema = yup.object().shape({
  reviewType: yup.mixed().oneOf(Object.values(ReviewType)).required(),
});

export const updateUserProfileSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .max(255, 'Email must be less than 255 characters'),
  password: yup.string().nullable().max(255),
});

export const updateUserEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .max(255, 'Email must be less than 255 characters'),
});

export const updateUserNameSchema = yup.object().shape({
  name: yup.string().required().max(255),
});

export const updateUserPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(255, 'Password must be less than 255 characters'),
  password_confirmation: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const updateUserSchema = yup.object({
  name: yup.string().optional().max(255),
  email: yup
    .string()
    .optional()
    .email('フォーマットが正しくありません')
    .max(255, '255文字以内で入力してください'),
  password: yup
    .string()
    .optional()
    .min(8, '8文字以上で入力してください')
    .max(255, '255文字以内で入力してください'),
  password_confirmation: yup
    .string()
    .optional()
    .oneOf([yup.ref('password')], 'パスワードが一致しません'),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .max(255, 'Email must be less than 255 characters'),
  password: yup
    .string()
    .required('Password is required')
    .max(255, '255文字以内で入力してください'),
  remember: yup.boolean().optional().default(false),
});

export const startGyakunatorSchema = yup.object().shape({
  questionId: yup.string().required(),
  roomId: yup.string().required(),
  timer: yup
    .number()
    .required()
    .min(2, 'validation.room.timerMin')
    .max(60, 'validation.room.timerMax'),
  question_quantity: yup
    .number()
    .required()
    .min(1, 'validation.room.quantityMin')
    .max(10, 'validation.room.quantityMin'),
  winnersLimit: yup.number().nullable(),
  life: yup.number().nullable().max(10),
});

export const createOriginalQuestionSchema = yup.object().shape({
  title: yup
    .string()
    .required('validation.createOriginalQuestions.titleRequired')
    .max(255, 'validation.createOriginalQuestions.titleMaxLength'),
  type: yup
    .mixed<OriginalQuestionType>()
    .oneOf(
      [OriginalQuestionType.NORMAL, OriginalQuestionType.RANDOM],
      '無効な質問タイプです',
    )
    .nullable('validation.createOriginalQuestions.typeRequired'),
  isPublished: yup.boolean().nullable().default(false),
});

export const createOriginalQuestionItemSchema = yup.object().shape({
  description: yup
    .string()
    .optional()
    .max(500, 'validation.createOriginalQuestionItem.descriptionMaxLength'),
  theme: yup
    .string()
    .required('validation.createOriginalQuestionItem.themeRequired')
    .max(255, 'validation.createOriginalQuestionItem.themeMaxLength'),
  themeDetail: yup
    .string()
    .optional()
    .max(4096, 'validation.createOriginalQuestionItem.themeDetailMaxLength'),
  answer: yup
    .string()
    .required('validation.createOriginalQuestionItem.answerRequired')
    .max(255, 'validation.createOriginalQuestionItem.answerMaxLength'),
});

export const reportOriginalQuestionSchema = yup.object().shape({
  message: yup.string().required().max(400),
});

export const postRewardSchema = yup.object().shape({
  zipCode: yup.string().required('郵便番号は必須です').max(8),
  address: yup.string().required('住所は必須です'),
  realName: yup.string().required('おなまえは必須です'),
  email: yup.string().required('メールアドレスは必須です'),
});
